import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
// import Text from "@ui/text";
import List, { ListItem } from "@ui/list";
import { HeadingType, ItemType } from "@utils/types";
import { PvPolicyWrapper, PvPolicyContent, StyledHeading } from "./style";

const PrivacyPolicySection = ({ data }) => {

  return (
    <PvPolicyWrapper id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            {data &&
              data.items.map((el, index) => (
                <PvPolicyContent key={index}>
                  {el && el.title && <StyledHeading>{el.title}</StyledHeading>}
                  {el && el.description && (
                    <Heading as="h6">{el.description}</Heading>
                  )}
                  {el && el.name && <Heading as="h3">{el.name}</Heading>}
                  <List>
                    {!!el.list &&
                      el.list.map((el, index) => (
                        <ListItem
                        key= {index}
                          dangerouslySetInnerHTML={{ __html: el.text }}
                        ></ListItem>
                      ))}
                  </List>
                </PvPolicyContent>
              ))}
          </Col>
        </Row>
      </Container>
    </PvPolicyWrapper>
  );
};

PrivacyPolicySection.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default PrivacyPolicySection;
