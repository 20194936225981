import styled, { device, themeGet } from "@styled";

export const PvPolicyWrapper = styled.section`
  padding: 60px 0;
  ${device.medium} {
    padding: 70px 0;
  }
  ${device.large} {
    padding: 90px 0;
  }
`;

export const PvPolicyContent = styled.div`
  max-width: 800px;
  &:not(:first-child) {
    margin-top: 50px;
  }
  h6 {
    margin-bottom: 20px;
  }
  h3 {
    border-left: 5px solid #fa4f19;
    padding-left: 20px;
    margin-bottom: 30px;
    color: ${themeGet("colors.secondaryDark")};
    line-height: 1.3;
  }
  ul {
    li {
      margin-bottom: 15px;
      font-size: 16px;
      span {
        display: block;
        line-height: 1.5;
        &.text-theme {
          color: ${themeGet("colors.primary")};
          display: inline;
        }
      }
      a {
        color: ${themeGet("colors.primary")};
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
export const StyledHeading = styled.h2`
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1.3;
  color: ${themeGet("colors.primary")};
  font-size: 30px;
  ${device.medium} {
    margin-bottom: 30px;
    font-size: 50px;
  }
`;
